<template>
  <base-material-card
    color="primary"
    icon="mdi-pencil"
    inline
    class="px-5 py-3"
  >
    <template v-slot:after-heading>
      <div class="display-1 font-weight-light">Informações Básicas</div>
    </template>
    <v-form class="pt-5">
      <v-row
        align="center"
        no-gutters
        class="ml-4"
      >
        <v-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Título"
          >
            <v-text-field
              label="Título"
              outlined
              :error-messages="errors"
              v-model="tituloLocal"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row
        align="center"
        no-gutters
        class="ml-4"
      >
        <v-col cols="12">
          <v-text-field
            label="Descrição (opcional)"
            outlined
            v-model="descricaoLocal"
          />
        </v-col>
      </v-row>
    </v-form>
  </base-material-card>
</template>

<script>
export default {
  props: {
    titulo: {
      type: String,
      required: true
    },
    descricao: {
      type: String
    }
  },
  computed: {
    tituloLocal: {
      get() {
        return this.titulo;
      },
      set(newValue) {
        this.$emit('update:titulo', newValue);
      }
    },
    descricaoLocal: {
      get() {
        return this.descricao;
      },
      set(newValue) {
        this.$emit('update:descricao', newValue);
      }
    }
  }
};
</script>
